import { lazy } from "react";

const Dashboard = lazy(() => import("../dashboard/Dashboard"));
const Login = lazy(() => import("../user-pages/Login"));
const UserList = lazy(() => import("../user/user-list"));
const UserProfile = lazy(() => import("../shared/user-profile"));
const ForgotPswd = lazy(() => import("../shared/forgot-password"));
const ChangePassword = lazy(() => import("../shared/change-password"));
const TarrifList = lazy(() => import("../tarrif/tarrif-list"));
const FeeList = lazy(() => import("../fee/fee-list"));
const CollectionPointList = lazy(() => import("../collection-point/collection-point-list"));
const ApplyLicence = lazy(() => import("../register/apply-licence"));
const ShowMsg = lazy(() => import("../shared/show-msg"));
const MakePayment = lazy(() => import("../register/make-payment"));
const PayWithSmartPay = lazy(() => import("../register/pay-with-smartpay"));
const FinancePendingApplication = lazy(() => import("../finance-user/pending-application"));
const VLDLPendingApplication = lazy(() => import("../VL-DL-user/pending-application"));
const ManifestList = lazy(() => import("../manifest/manifest-list"));
const TrackApplication = lazy(() => import("../license/track-application"));
const DashboardNew = lazy(() => import("../dashboard/dashboard-new"));
const ClosedApplication = lazy(() => import("../VL-DL-user/closed-application"));
const TotalCollectionsReport = lazy(() => import("../reports/totalCollectionsReport"));
const TotalServiceChargeReport = lazy(() => import('../reports/totalServiceChargeReport'));
const TotalTariffsReport = lazy(() => import('../reports/totalTariffsReport'));
const TotalDeliveryChargeReport = lazy(() => import('../reports/totaldeliveryReport'));
const TotalPickupChargeReport = lazy(() => import('../reports/totalPickupReport'));
const TotalGeographicalDistributionReport = lazy(() => import('../reports/totalGeographicalDistributionReport'))
const UnpaidApplication = lazy(() => import("../VL-DL-user/unpaid-application"))


const routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   component: Dashboard,
  //   layout: "/admin",
  //   common: true,
  //   icon: "icon-screen-desktop menu-icon"
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardNew,
    layout: "/admin",
    common: true,
    icon: "icon-screen-desktop menu-icon"
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    hideFromMenu: true
  },
  {
    path: "/users",
    name: "Users",
    component: UserList,
    layout: "/admin",
    icon: "mdi mdi-account-multiple-outline menu-icon"
  },
  {
    path: "/user-profile",
    name: "My Profile",
    component: UserProfile,
    layout: "/admin",
    icon: "mdi mdi-account-multiple-outline menu-icon",
    hideFromMenu: true
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPswd,
    layout: "/auth",
    icon: "mdi mdi-account-multiple-outline menu-icon",
    hideFromMenu: true
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: ChangePassword,
    common: true,
    layout: "/admin",
    icon: "mdi mdi-account-multiple-outline menu-icon",
    hideFromMenu: true
  },
  {
    path: "/tarrif-list",
    name: "Tariffs",
    component: TarrifList,
    layout: "/admin",
    icon: "mdi mdi-database menu-icon",
  },
  {
    path: "/fee-list",
    name: "Fees",
    component: FeeList,
    layout: "/admin",
    icon: "mdi mdi-forum menu-icon",
  },
  {
    path: "/collection-point-list",
    name: "Collection Point",
    component: CollectionPointList,
    layout: "/admin",
    icon: "mdi mdi-source-merge menu-icon",
  },
  {
    path: "/apply-service",
    name: "Licence Application",
    component: ApplyLicence,
    layout: "/auth",
    icon: "mdi mdi-source-merge menu-icon",
    hideFromMenu: true
  },
  {
    path: "/show-msg",
    name: "SHOW MSG",
    component: ShowMsg,
    layout: "/auth",
    icon: "mdi mdi-source-merge menu-icon",
    hideFromMenu: true
  },
  {
    path: "/make-payment",
    name: "Make Payment",
    component: MakePayment,
    layout: "/auth",
    icon: "mdi mdi-source-merge menu-icon",
    hideFromMenu: true
  },
  {
    path: "/pay-with-smartpay",
    name: "Smart Pay Payment",
    component: PayWithSmartPay,
    layout: "/auth",
    icon: "mdi mdi-source-merge menu-icon",
    hideFromMenu: true
  },
  {
    path: "/pending-application",
    name: "Pending Applications",
    component: FinancePendingApplication,
    layout: "/finance",
    icon: "mdi mdi-bookmark-check menu-icon",
  },
  {
    path: "/add-serviceOrder-application",
    name: "Pending Applications",
    component: VLDLPendingApplication,
    layout: "/vlUser",
    allow: ["/vlUser", "/dlUser", "/admin", "/executiveUser"],
    icon: "mdi mdi-bookmark-check menu-icon"
  },
  {
    path: "/manifest-list",
    name: "Manifests",
    component: ManifestList,
    layout: "/coordinatorUser",
    icon: "fa fa-suitcase menu-icon"
  },
  {
    path: "/track-application",
    name: "Track Application",
    component: TrackApplication,
    layout: "/auth",
    hideFromMenu: true
  },
  {
    path: "/closed-application",
    name: "Closed Applications",
    common: true,
    component: ClosedApplication,
    layout: "/admin",
    icon: "mdi mdi-border-outside menu-icon"
  },
  {
    path: "/unpaid-applications",
    name: "Unpaid Applications",
    component: UnpaidApplication,
    layout: "/admin",
    icon: "mdi mdi-printer menu-icon"

  },
  {
    path: "/total-collections-reports",
    name: "Total Collections Report",
    component: TotalCollectionsReport,
    layout: "/admin",
    icon: "mdi mdi-chart-pie menu-icon",
  },
  {
    path: "/total-tariffs-reports",
    name: "Tariffs Report",
    component: TotalTariffsReport,
    layout: "/finance",
    allow: ["/executiveUser", "/finance"],
    icon: "mdi mdi-chart-areaspline menu-icon",
  },
  {
    path: "/total-service-fee-reports",
    name: " Service Fees Report",
    component: TotalServiceChargeReport,
    layout: "/admin",
    icon: "mdi mdi-view-dashboard menu-icon",
  },
  {
    path: "/total-delivery-fee-reports",
    name: " Delivery Fees Report",
    component: TotalDeliveryChargeReport,
    layout: "/admin",
    icon: "mdi mdi-truck-delivery menu-icon",
  },
  {
    path: "/total-pickup-fee-reports",
    name: " Pickup Fees Report",
    component: TotalPickupChargeReport,
    layout: "/admin",
    icon: "mdi mdi-home-map-marker menu-icon",
  },
  {
    path: "/total-geographical-distribution-reports",
    name: " Geographical  Report",
    component: TotalGeographicalDistributionReport,
    layout: "/admin",
    icon: "mdi mdi-earth menu-icon",
  },
]

export default routes;
